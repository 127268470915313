.image-size-1{
    width: 7%;
}
.image-size-2{
    width: 15%;
}
.image-size-3{
    width: 24%;
}
.image-size-4{
    width: 32%;
}
.image-size-5{
    width: 40%;
}
.image-size-6{
    width: 49%;
}
.image-size-7{
    width: 57%;
}
.image-size-8{
    width: 65%;
}
.image-size-9{
    width: 74%;
}
.image-size-10{
    width: 82%;
}
.image-size-11{
    width: 90%;
}
.image-size-12{
    width: 100%;
}