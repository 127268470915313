* {
    font-family: $family ;
    font-size: 15px ;
    color: $black;
    font-weight: $regular;
}

h1, h2.bold, h3, h4, h5 {
    font-weight: $bold;
}



.MuiInputBase-input{
    font-family: $family !important;
}



.MuiTableCell-head{
    padding-top: 0 !important;
}

.MuiTableCell-root {
    padding-left: 0 !important;
}

.search-page{
    min-height: 90vh;
}

.empty-div{
    height: 0px;
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3000;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: $white;
    left: 0;
    top: 0;
}

.bold{
    font-weight: $bold;
}

.cursor-pointer{
    cursor: pointer;
}

h1 {
    font-size: 20px;
}

h2 {
    font-size: 17px;
}

img{
    width:100%;
}

.fade-in {
    opacity: 0;
}

button{
    font-size: 15px;
    border: 1px solid $black;
    background-color: unset;
    padding: 5px 10px;
}

strong {
    font-weight: $bold;
}

a{
    outline: none;
    color:$purple;
    font-weight: $medium;
    text-decoration: none;
}
  
p {
    margin-bottom: 10px;
    margin-top: 10px;
}

section:not(.no-padding){
    padding: #{$spacing-mob}px;
}

.d-none{
    display: none !important;
}

.italic{
    font-style: italic;
}

.purple{
    color: $purple;
}

.medium{
    font-weight: $medium;
}
.regular{
    font-weight: $regular;
}

.italic{
    font-style: italic;
}

.align-right{
    text-align: right;
}

// overide the material ui form input fields styling
.MuiFormControl-root {
    *{
        font-family: $family !important;
    }
}

.MuiFormLabel-root.Mui-focused{
    color: $purple !important;
}

.MuiInput-underline:after{
    border-bottom: 2px solid $purple !important;
}


.page-section{
    .left-section{
        margin-bottom: 33px;
        strong{
            font-size: 20px;
        }
        p{
            font-weight: $bold;
            font-size: 13px;
        }
        .link-section{
            margin-top:10px;
            display: flex;
            flex-direction: column;
            *{
                font-size: 13px;
                color: $black;
            }
            *:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}

.article-section{
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $black;
    display: flex;
    justify-content: space-between;
    &.first-item {
        border-top: 1px solid $black;
    }
    span{font-size: 16px;}
    a{color: $black; }
    &-details{
        width:100%;
        div{
            display: flex;
            justify-content: space-between;
            span{
                width:80%;
            }
            a{
                text-align: right;
                font-size: 15px;
            }
        }

    }
}

.list-wrapper{
    margin-top: 20px;
    margin-bottom: 20px;
    border-top:1px solid $black;
    border-bottom:1px solid $black;
    .list-details{
        display: flex;
        flex-direction: column;
        padding: 10px 0 ;
        &:not(:last-child){
            border-bottom:1px solid $black;
        }
        .number{
            font-size: 20px;
            font-weight: $bold;
            padding-bottom:10px;
        }
    }
    
}

.article-section{
    span{font-size: 20px;}
}

.table-styles{
    margin-bottom: 40px;
    h2 {
        margin: 0;
    }
    .MuiTableCell-root{
        border-bottom: 1px solid $black;
        padding: 10px 5px;
    }
    *{
        font-size: 12px;
    }
}

.service-details-wrapper{
    padding-top: 40px;
    .service-details{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .service-name{
            width: 70%;
            h2{
                margin-bottom:5px ;
            }
            p{
                margin-bottom: 0;
                padding:5px 0;
            }
        }
        a{
            padding:5px 0;
            color:$purple;
            text-decoration: none;
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    * {
        font-size: 17px ;
    }
    .contact-section{
        padding-top: 0 !important;
    }
    .empty-div{
        height: 60px;
    }
    .sticky {
        top: 100px !important;
    }
    
    .search-page{
        min-height: 60vh;
    }

    .desk-d-none{
        display: none !important;
    }

    // .desk-mob-none{
    //     display: block !important;
    // }
    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 20px;
    }

    p {
        margin-bottom: 15px;
        margin-top: 15px;
    }

    section:not(.no-padding){
        padding: #{$spacing-desk}px;
    }

    .page-section{
        display: flex;
        justify-content: space-between;

        .left-section{
            width: 17%;
            .link-section{
                margin-top:20px;

                >*{
                    margin-bottom: 10px;
                }
            }
        }

        .right-section{
            width: 75%;
        }
    }

    .list-wrapper{
        margin-top: 50px;
        margin-bottom: 50px;
        width:60%;
        .list-details{
            padding: 15px 0;
            flex-direction: row;
            align-items: center; 
            .number{
                width: 30%;
                padding: 0;
            }
        }
        
    }

    .table-styles{
        margin-bottom: 50px;
        .MuiTableCell-root{
            padding: 16px;
        }
        *{
            font-size: 16px;
        }
        h2{
            font-size: 20px !important;
        }
    }
    .service-details-wrapper{
        padding-top: 50px;
        display: flex;
        align-items: flex-end;
        img{
            width: 60%;
        }
        .service-details{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 40%;
            padding-left: 20px;
            .service-name{
                p{
                    margin-bottom: 0;
                }
            }
        }
    }
}