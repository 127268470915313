.page-section{
    .team-desc{
       h2{
           font-weight: $bold;
       } 
       margin-bottom: 40px;
    }

    .available-pos{

        h2{
            font-weight: $bold;
            margin-bottom: 15px;
        }

        &-container{
            border-top: 1px solid;
        }

        &-wrapper{
            &:not(:last-child){
                border-bottom: 1px solid;
            }

            a{
                margin-bottom: 20px;
                display: block;
            }
        }

        &-info{
            margin-top: 20px;
        }
      
    }

    .apply-wrapper {
        border-top: 1px solid $black;
        padding-bottom: 15px;
    
        form {
            > *{
                width: 100%;
                margin-bottom: 10px;
            }
            .select-field{
                margin-top: 15px;
            }
            .action-btns{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                .submit-btn{
                    cursor: pointer;
                    margin-top: 10px;
                }
                .error-msg{
                    color: #ED2124;
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0 !important;
                    font-family: $family;
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .MuiFormControl-root{
        .MuiInput-root input[type="file"]{
            opacity:0;
        }
        .MuiFormLabel-filled + div input {
            opacity: 1 !important;
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .page-section{
        .team-desc{
            width: 70%;

           h2{
               font-weight: $bold;
               margin-top: 0;
           } 
           margin-bottom: 40px;
        }
    
        .available-pos{
    
            h2{
                font-weight: $bold;
                margin-bottom: 15px;
            }
    
            &-container{
                border-top: 1px solid;
            }
    
            &-wrapper{
                &:not(:last-child){
                    border-bottom: 1px solid;
                }
            }
    
            &-info{
                margin-top: 20px;
            }
          
        }
    
        .MuiFormControl-root{
            .MuiInput-root input[type="file"]{
                opacity:0;
            }
            .MuiFormLabel-filled + div input {
                opacity: 1 !important;
            }
        }
    }
}