.media-page {
    .right-section{
        &.mediacenter-page{
            .posts-section{
                img{
                    padding-bottom: 10px;
                    border-bottom: 1px solid;
                }
                .press-section{
                    margin-top:40px;
                    margin-bottom: 40px;
                    &-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: $purple;
                            opacity: 1; /* Firefox */
                        }
                        
                        :-ms-input-placeholder { /* Internet Explorer 10-11 */
                         color: $purple;
                        }
                        
                        ::-ms-input-placeholder { /* Microsoft Edge */
                            color: $purple;
                        }
                        .search-input{
                            border: none;
                            outline: none;
                            color: $purple;
                            text-align: right;
                            
                        }
                    }
                    &-articles{
                        border-top:1px solid $black;
                        border-bottom:1px solid $black;
                        &-wrapper{
                            display: flex;
                            align-items: flex-end;
                            justify-content: space-between;
                        }
                       
                        >*:not(:last-child){
                            border-bottom:1px solid $black;
                        }
                        >*{
                            padding-bottom:15px;
                        }
                        &-info{
    
                            p{
                                margin-bottom:0px;
                                margin-top: 15px;
                            }
                            h2{
                                margin-top:5px;
                                margin-bottom:0px;
                            }
                        }
                        &-link{
                            text-align: right;
                            a{
                                color: $black;
                            }
                        }
    
                    }
                }
                .press-kit{
                    h2{
                        border-bottom: 1px solid $black;
                        padding-bottom: 10px;
                        margin-bottom:0;
                    }
                }
                .press-inquiries{
                    display: flex;
                    flex-direction: column;
                    margin-top:30px;
                    h1{
                        width:80%;
                    }
                    span, a {
                        color:$black;
                        margin-bottom: 5px;
                        font-size: 17px;
                        font-weight: $medium;
                    }
                }
            }
        }

        &.lifestyle-page{
            .blog-section{
                .blog-article{
                    &-info{
                        margin-bottom:60px;
                        &-date{
                            font-weight: $medium;
                            font-size: 17px;
                            margin-bottom:30px;
                        }
                    }
                }
                .images-sections{
                    h2{
                        font-size: 30px;
                        font-weight: $bold;
                    }
                    &-first-row{
                        img{
                            margin-bottom:20px;
                        }
                    }
                    &-second-row{
                        img{
                            margin-bottom:20px;
                        }
                    }
                }
            }
        }

        &.blog-page{
            .lifestyle-blog-page{
                .lifestyle-blog-meta{
                    h2{
                        margin-bottom: 10px;
                    }
                    span{
                        font-weight: $medium;
                    }
                }
                .lifestyle-blog-content{
                    p{
                        margin-bottom:20px;
                        margin-top:20px ;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .media-page{
        .right-section{
            &.mediacenter-page{
                .posts-section{
                    .press-section{
                        margin-top:60px;
                        margin-bottom: 60px;
                        &-articles{
                            
                            >*{
                                padding-bottom: 20px;
                            }
                            &-info{
                                p{
                                    margin-top:20px;
                                }
                            }
                        }
                    }
                    .press-inquiries{
                        margin-top: 50px;
                        span, a {
                            margin-bottom:10px;
                        }
                    }
                }
            }

            &.lifestyle-page{
                .blog-section{
                    .blog-article{
                        display: flex;
                        margin-bottom: 40px;
                        &-image{
                            width: 50%;
                        }
                        &-info{
                            width: 50%;
                            padding-left: 25px;
                            margin-bottom: unset;
                            > h2{ margin: 0;}
                            p:not(.blog-article-info-date){ margin: 0 0 10px;}
                            &-date{
                                font-size: 20px;
                                margin-top: 5px;
                                margin-bottom: 40px;
                            }
                        }
                    }
                    .images-sections{
                        &-first-row{
                            display: flex;
                            justify-content: space-between;
                            .img1{width: 30%;}
                            .img2{width: 68%;}
                        }
                        &-second-row{
                            display: flex;
                            justify-content: space-between;
                            .img1{width: 30%;}
                            .img2{width: 48%;}
                            .img3{width: 18%;}
                        }
                    }
                }
            }
            &.blog-page{
                padding-right: 15%;
                box-sizing: border-box;
                .lifestyle-blog-page{
                    .lifestyle-blog-meta{
                        h2{
                            margin-bottom: 10px;
                        }
                    }
                    .lifestyle-blog-content{
                        p{
                            margin-bottom:35px;
                            margin-top:35px ;
                        }
                    }
                }
            }
        }
    }
}