.governance-page {
  .right-section {
    &.governance-framework {
      .framework-img {
        margin-bottom: 40px;
        width: 90%;
      }
      .accordion-item__content {
        padding-bottom: 0;
      }
      .framework-details-container {
        span {
          margin-bottom: 30px;
          display: block;
        }
        .director-details {
          display: flex;
          border-top: solid black 1px;

          &-name {
            font-weight: 700;
            width: 40%;
          }
          &-occupation {
            width: 60%;
          }
        }
        .board-committees {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          > div {
            .member-details {
              border-bottom: 1px solid $black;
              padding-bottom: 15px;
            }
            width: 47%;
            margin-bottom: 30px;
            h2 {
              margin-bottom: 0px;
              padding-bottom: 10px;
              border-bottom: 1px solid $black;
              font-weight: $bold;
            }
            p {
              padding-bottom: 0;
              &:first-child {
                font-weight: $medium;
              }
              &:not(:first-child) {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
    &.governance-complaints {
      ul {
        list-style: url(../img/right-arr.svg);
        padding-left: 35px;
        padding-top: 10px;
        li {
          padding-left: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: $breakpoint) {
  .governance-page {
    .left-section {
      width: 32%;
    }
    .right-section {
      &.governance-framework {
        .framework-img {
          margin-bottom: 50px;
        }
        .framework-details-container {
          width: 85%;
          span {
            width: 84%;
          }
        }
      }
    }
  }
}
