.accordion-list {
  list-style: none;
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &__item {
    cursor: pointer;
    & + & {
      border-top: 1px solid $black;
    }
  }
}

.accordion-item {
  $self: &;

  &--opened {
    #{ $self }__icon {
      &:before {
        content: '-';
      }
    }
    #{ $self }__title {
      font-weight: $bold;
    }

    #{ $self }__inner {
      max-height: 100rem;
    }

    #{ $self }__content {
      opacity: 1;
    }
  }

  &__line {
    display: block;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__title {
    margin: 0;
    font-weight: 500;
    color: $black;
    padding-right: 8px;
  }

  &__icon {
    width: 1.2rem;
    height: 1.2rem;
    font-weight: $medium;
    &:before {
      content: '+';
    }
  }

  &__inner {
    max-height: 0;
    position: relative;
  }

  &__content {
    opacity: 0;
    padding: 0 0 1.2rem;
  }

  &__paragraph {
    margin: 0;
    font-size: 1rem;
    color: $black;
    font-weight: 300;
    line-height: 1.3;
    ul {
      list-style: url(../img/right-arr.svg);
      padding-left: 35px;
      padding-top: 10px;
      > li {
        padding-left: 15px;
        padding-bottom: 15px;
      }
    }
    a {
      text-decoration: underline;
      color: $black;
    }
    p {
      margin: 0;
      padding: 15px 0;
    }
    img {
      width: 100%;
    }
  }

  &__link {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    font-size: 13px;
  }
}

@media only screen and (min-width: $breakpoint) {
  .accordion-item {
    $self: &;
    &__paragraph {
      img {
        width: 75%;
      }
    }
  }
}
