.footer-d-content {
    display: none;
}

.footer-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px #{$spacing-mob}px 30px;
    font-weight: $medium;

    > *:not(:last-child) {
        margin-bottom: 15px;
    }

    .footer-icons{
        display:flex;
        justify-content: center;
        align-items: center;

        a{
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .footer-policies {
        width: 100%;
        display:flex;
        justify-content: space-between;
        align-items: center;

        a {
            font-size: 13px;
            text-decoration: none;
            color: $black;
        }
    }

    .footer-copy-rights{
        span{
            font-size: 13px;
        }
    }
}


@media only screen and (min-width: $breakpoint) {

    .footer-container{
        flex-direction: row;
        justify-content: space-between;

        padding: #{$spacing-desk}px;
        font-weight: $medium;
    
        > *{
            &:not(:last-child) {
                margin-bottom: unset;
            }
            width: 33% !important;
        }
    
        .footer-icons{
            order: 2;
            display:flex;
            justify-content: center;
            align-items: center;
        }
    
        .footer-policies {
            order: 3;
            a {
                width: 33%;
                text-align: center;
            }
        }
    
        .footer-copy-rights{
            order:1;
        }
    }

    .footer-d-content {
        padding-left: #{$spacing-desk}px;
        padding-right: #{$spacing-desk}px;
        padding-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .footer-logo {
            width: 10%;
        }

        .footer-nav-section {
            width: 86%;
            display: flex;
            justify-content: space-between;
            [class^=footer-nav-section]{
                .footer-nav-content{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    &-title{
                        font-size: 15px;
                        text-decoration: underline;
                        margin-bottom: 10px;
                    }
    
                    &-subtitle{
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
    
                    a{
                        color: $black;
                        font-size: 13px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        
    }
}