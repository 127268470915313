.search-page {
    .no-results{
        padding-left: 20px;
    }
    .search-input-section{
        padding-top: 20px;
    }
    .show-more-btn{
        margin-top: 20px;
        display: block;
    }
    .search-result-section{
        .page-section{
            .left-section{
                p{
                    margin: 0;
                }
                margin: 0;
            }

            .right-section{
                .last-item{
                    border-bottom: 1px solid $black;
                }
                .media-page{

                    
                    .service-details-wrapper{
                        padding-top: 0;
                        .lazyload-wrapper{
                            img{width: 100%;}
                        }
                    }
                    padding-bottom:40px;

                    .search-blog-result{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid $black;
                        p{
                            width:50%;
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint) {

    .search-page {
        .no-results{
            padding-left: 17%;
        }
        .search-input-section{
            padding-top: 40px;
        }
        .right-section{
            .first-item{
                padding-top:0 !important;
            }
            .media-page{
                padding-top: 20px;
                padding-bottom: 20px !important;
                &:not(:last-child){

                }
                .service-details-wrapper{
                    padding-top: 0;
                    .lazyload-wrapper{
                        width: 60%;
                        img{width: 100%;}
                    }
                }
            }
            .search-blog-result{
                p{
                    margin-top: 0;
                }
            }
        }
    }
}