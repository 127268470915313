.shareholders-page{
    .MuiTableCell-head {
        vertical-align: text-top !important;
        line-height: 1.3 !important;
    }
    .shareholders{
        h1{
            margin-top: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid $black;
            font-size: 20px !important;
        }
    }
    .dividends{
        h1{
            margin-top: 0;
            padding-bottom: 10px;
            border-bottom: 1px solid $black;
            font-size: 20px !important;
        }
    }
}
.investor-page {
    .right-section{
        *{font-size: 13px !important; font-family: $family !important;}
        
        .article-component-section-link{
            padding-top: 15px;
            display: block;
        }
        .article-component-section-listing{
            margin-bottom: 40px;
        }
        &.analyst-page{
            .coverage-sections{
                .coverage-downloads-section{
                    margin-top:15px;
                }
            }
        }
    }
}


@media only screen and (min-width: $breakpoint) {
    .investor-page{
        .right-section{
            *{font-size: 17px !important;}
            .article-component-section-listing{
                margin-bottom: 50px;
                > h2 {
                    margin-top: 0;
                }
            }
            &.credit-rating-page{
                .credit-rating-sections{
                    .creditrating-italic-section{
                        margin-bottom:25px;                
                    }
                }
            }
            &.analyst-page{
                .coverage-sections{
                    .coverage-downloads-section{
                        margin-top:25px;
                    }
                }
            }
        }
    }  
}