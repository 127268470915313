$family: 'Montserrat', sans-serif;
$familyA: 'Almarai', sans-serif;
$breakpoint: 1024px;

//@todo for development purposes, remove later on
// $breakpoint: 750px;
$purple: #80008D;
$black: #000;
$white: #FFFFFF	;
$grey: #C3C3C3;

$medium: 500;
$regular: 400;
$bold: 700;

$spacing-mob: 20;
$spacing-desk: 40;

@mixin truncate($maxWidth) {
    max-width: $maxWidth;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}