.expertise-page{
    .right-section{
        &.sectors-page{
            .sector-details{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border-bottom:1px solid $black;
                *{
                    padding: 15px 0;
                }
                &-name {
                    font-size: 17px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                &:first-child{
                    border-top: 1px solid;
                }
                a{
                    color:$black;
                }

            }
        }
        &.locations-page{
            .location-details{
                display: flex;
                justify-content: space-between;
                width: 100%;
                border-bottom:1px solid $black;
                *{
                    padding: 15px 0;
                }
                &-name {
                    font-size: 17px;
                    font-weight: 400;
                }
                &:first-child{
                    border-top: 1px solid;
                }
                a{
                    color:$black;
                }
            }
        }
    }
}


@media only screen and (min-width: $breakpoint) {
    .expertise-page{
        .right-section{
            &.overview-page{
                .overview-wrapper{
                    width:90%;
                    h2{
                        margin-top: 0;
                    }
                }
            }
            &.services-page{
                .accordion-list{
                    width:90%;
                }
                .accordion-item__title{
                    text-transform: uppercase;
                }
            }
            &.sectors-page{
                .sector-details{
                    width: 80%;
                }
            }
            &.locations-page{
                .location-details{
                    width: 100%;
                }
            }
            &.details-single-page{
                .details-container{
                    width: 80%;
                    >span{
                        display: block;
                        margin-bottom: 50px;
                    }
                }
                .list-wrapper{
                    margin-top: 0px;
                }
                .desc-card{
                    align-items: unset;
                    &-text{
                        display: flex;
                        flex-direction: column;
                        justify-content: inherit;
                    }
                    h1{
                        margin: 0;
                    }
                }
            }
        }
    }
}