.properties-page{
    .right-section{
        &.properties-page-content{
            .service-details-wrapper{
                .service-details{
                    border-bottom:1px solid $black;
                    padding-bottom: 15px;
                    .service-name{
                        max-width: 62%;
                        p{
                            margin:0;
                            padding-top:0;
                        }
                    }
                }
                .lazyload-wrapper{
                    a{
                        width: 100%;
                    }
                }
            }
        }
    }
    .left-section{
        .search-field{
            width:100%;
            label{
                font-size: 13px;
                &.MuiInputLabel-shrink {
                    transform: translate(0, 8.5px) scale(0.75);
                }
            }
        }

        .MuiInput-underline:before{
            border:none;
        }
        .property-filter{
            font-size: 13px;
            color: $grey;
            padding-bottom: 10px;
            display: block;
        }
        .filters{
            cursor: pointer;
            text-transform: uppercase;
            width:60%;
            .filter{

                *{
                    font-weight: $medium;
                    &.bold{
                        font-weight: $bold;
                    }
                }
                &:first-child{
                    border-top:1px solid $black;
                }
                border-bottom: 1px solid $black;
                &-title{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img{
                        width: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .properties-page{
        .right-section{
            &.properties-page-content{
                .service-details-wrapper{
                    &:first-child{
                        padding-top:0;
                    }
                    .lazyload-wrapper{
                        width:60%;
                        a{
                            display: flex;
                        }
                    }
                    img{
                        width:100%;
                        height: 100%;
                    }
                    .service-details{
                       padding-left:0;
                       margin-left:20px;

                    }
                }
            }
        }

        .left-section{

            .filters{
                width:100%;
            }
        }
    }

}