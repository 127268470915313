.firm-page{
    .right-section{
        &.profile-page{
            .networth{
                margin-top: 20px;
                border-bottom:1px solid $black;
                border-top:1px solid $black;
                >*:not(:last-child){
                border-bottom:1px solid $black;
                }
                &-wrapper{
                    padding-bottom:10px;

                    h2{
                        margin-top: 10px;
                        margin-bottom:5px;
                    }
                }
            }
            .mission-vision{
                margin-top:40px;
                display: block;
                border-bottom: 1px solid $black;
                padding-bottom:20px;
            }
            .ceo-saying{
                margin-top:40px;
                font-style:italic;
                &-text{
                    display: block;
                    margin-bottom:10px;
                    font-size: 20px;
                }
                .ceo-name{
                    font-size: 20px;
                }.ceo-title{
                    font-size: 20px;
                }
                .ceo-details {
                    padding-left: 10px;
                    position: relative;
                    width:80%;
                }
                
                .ceo-details:before {
                    position:absolute;
                    content:"-";
                    left:0;
                    top:0;
                    font-size:20px;
                }
            }
        }
        &.sustainability-page{
            .desc-card{
                padding:0px;
                margin-top:40px;
                &-title{
                    font-size:30px;
                }
                &-info{
                    margin-bottom:20px;
                }
            }
        }
        &.social-page{
            .social-page-text{
                margin-bottom:20px;
            }
            .desc-card{
                padding:0px;
                margin-top:40px;
                &-title{
                    font-size:30px;
                }
                &-info{
                    margin-bottom:20px;
                }
            }
        }
        &.awards-page{
            .social-page-text{
                margin-bottom:20px;
            }
        }
        &.leadership-page{
            .broad-members{
                margin-bottom: 60px;
                h2{
                    border-bottom:1px solid $black;
                    padding-bottom:10px;
                    margin-bottom: 0;
                }
                .member-details-wrapper{
                    .member-details{
                        display:flex;
                        justify-content: space-between;
                        font-size: 17px;
                        border-bottom: 1px solid $black;
                        padding-top:10px;
                        p{
                            margin-top:0;
                        }
                        a{
                            color:$black;
                        }
                    }             
                }
            }
            .management-members{
                margin-bottom: 40px;

                h2{
                    border-bottom:1px solid $black;
                    padding-bottom:10px;
                    margin-bottom: 0;
                }
                .member-details-wrapper{
                    .member-details{
                        display:flex;
                        justify-content: space-between;
                        font-size: 17px;
                        border-bottom: 1px solid $black;
                        padding-top:10px;
                        p{
                            margin-top:0;
                        }
                    }             
                }
            }
        }
        &.leader-details-page{
            .leader-name{
                h2{margin-bottom:0;}
                p{
                    margin-top:0;
                    font-size: 17px;
                }
            }
            .leader-info{
                img{width:80%;}
            }

        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .firm-page{
        .page-section{
            padding-top: 0;
        }
        .left-section{
            padding-top: 40px;
        }
        .right-section{
            padding-top: 40px;
            h2{
                margin-bottom:25px;
                margin-top: 0;
                p{
                    margin-top: 0;
                }
            }
            &.profile-page{
                padding-top: 2px;
                padding-top: 0;
                img{
                    margin-bottom:40px;
                }
                .profile{width:70%;}
                .networth{
                    margin-top: 40px;
                    width:70%;
                    &-wrapper{
                        padding-bottom:20px;
                        padding-top: 20px;
                        display: flex;
                        align-content: center;
                        h2{
                            margin:0;
                            width:40%;
                        }
                        span{
                            text-align: left;
                            width: 60%;
                        }
                    }
                }
                .mission-vision{
                    margin-top:40px;
                    display: flex;
                    border-bottom: 1px solid $black;
                    padding-bottom:20px;
                    h1{
                        margin-bottom: 10px;
                    }
                    h2{
                        margin-top:0;
                    }
                    .MISSION{
                        width: 33%;
                        padding-right:50px;
                    }
                    .VISION{
                        width: 33%;
                        padding-left:50px;
                    }
                }
                .ceo-saying{
                    width: 80%;
                    &-text{
                        font-size: 30px;
                    }
                    .ceo-name{
                        font-size: 30px;
                    }.ceo-title{
                        font-size: 30px;
                    }
                }
            }
            &.history-page{
                img{
                    margin-top: 20px;
                }
                span{
                    font-size: 20px;
                    width:85%;
                    display: block;
                }
            }
            &.sustainability-page{
                .sustainability-page-text{
                    width: 80%;
                }
                .desc-card{
                    align-items: flex-end;
                    padding:0px;
                    margin-top:40px;
                    &-image{
                        width:55%;
                    }
                    &-text{
                        width:40%;
                    }
                    &-title{
                        font-size:30px;
                        margin-bottom:20px;
                        width: 70%;
                        margin-top: 0;
                    }
                }
            }
            &.social-page{
                .accordion-list{
                    width:78%;
                    .accordion-item__paragraph{
                        width: 90%;
                    }
                }
                .social-page-text{
                    width: 80%;
                    margin-bottom:30px;

                }
                .desc-card{
                    align-items: flex-end;
                    padding:0px;
                    margin-top:40px;
                    &-image{
                        width:55%;
                    }
                    &-text{
                        width:40%;
                    }
                    &-title{
                        font-size:30px;
                        margin-bottom:20px;
                        width: 70%;
                        margin-top: 0;
                    }
                }
            }
            &.awards-page{
                .social-page-text{
                    width: 91%;
                    margin-bottom:30px;
                }
                .accordion-list{
                    width:90%;
                }
            }
            &.leadership-page{
                .broad-members{
                    width: 90%;
                    margin-bottom: 60px;
                    margin-top:40px;
                    h2{
                        border-bottom:unset;
                    }
                    .member-details-wrapper{
                        display:flex;
                        justify-content: space-between;
                        >*{
                            border-top:1px solid;
                        }
                        .member-details{
                            width: 48%;
                            display:flex;
                            justify-content: space-between;
                            font-size: 20px;
                            border-bottom: unset;
                            padding-top:15px;
                            p{
                                margin-top:0;
                                margin-bottom: 10px;
                            }
                        }             
                    }
                }
                .management-members{
                    width: 90%;
                    margin-bottom: 60px;
                    margin-top:40px;
                    h2{
                        border-bottom:unset;
                    }
                    .member-details-wrapper{
                        display:flex;
                        justify-content: space-between;
                        >*{
                            border-top:1px solid;
                        }
                        .member-details{
                            width: 48%;
                            display:flex;
                            justify-content: space-between;
                            font-size: 20px;
                            border-bottom: unset;
                            padding-top:15px;
                            p{
                                margin-bottom: 10px;
                                margin-top:0;
                            }
                        }             
                    }
                }
            }
            &.leader-details-page{
                .leader-info{
                    display: flex;
                    align-items: flex-start;
                    img{width:18%;}
                    p{padding-left:25px; margin-top: 0;width: 60%;}
                }
    
            }
        }
    }
}
