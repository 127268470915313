body.ar {
    direction: rtl;
    *{
        font-family: $familyA;
    }
}

@media only screen and (min-width: 1024px){
    body.ar{
        .footer-phone {
            direction: ltr;
            text-align: right;
        }
        .error-msg{
            left: unset;
            right: 0;
        }
        .leader-desc {
            padding-left: 0;
            padding-right: 25px;
        }
        .MuiInputLabel-formControl {
            width: 100%;
            text-align: right;
        }
        .MuiInputLabel-shrink {
            transform-origin: top right;
        }
        .MuiInputBase-input{
            text-align:right;
        }
        .MuiInputLabel-animated {
            transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        }
        .MuiSelect-select.MuiSelect-select {
            padding-right: 0px !important;
        }
        .dmenu-container {
            .dmenu-wrapper {
                .dmenu-ul {
                    padding-right: 0;
                    li:not(:last-child) {
                        margin-left: 40px;margin-right:0px;
                    }
                } 
            }
        }
        .service-details{
            padding-left: 0;
            padding-right: 20px;
        }
        .home-header-text {
            margin: 0 40px 40px 0;
        }
        .properties-page{
            .right-section.properties-page-content {
                    .service-details-wrapper{
                        .service-details{
                            margin-left: 0;
                            margin-right: 20px;
                        }

                    }
            }
        } 
        .MuiTableCell-root{
                text-align: right;
        }

    }
    
}