.property-single-page{
    &-header{
        height: 90vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        position: relative;
        &-title{
            margin: 0;
            color: $white;
            position: absolute;
            bottom: #{$spacing-mob}px;
            left: #{$spacing-mob}px;
            text-transform: uppercase;
        }
    }

    &-headerlinks{
        &-wrapper{
            display: flex;
            justify-content: space-between;
        }
        &-left{
            display: flex;
            flex-direction: column;
            .tag{
                @include truncate(200px);
            }
        }
        &-right{
            display: flex;
            font-size: 20px;
            .share{
                display: none;
            }
            a{
                padding-left:10px ;
            }
        }
    }

    &-content{
        &-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-left{
            order:2;
            
        }
        &-property{
            margin-bottom:40px;
        }
        &-right{
            order:1;
        }

        &-propertymeta{
            margin-bottom:20px;
            >*{
                margin-bottom: 5px;
            }
        }
        &-components{
            margin-bottom: 10px;
            >*{
                margin-top: 5px;
            }
        }
    }
    .property-single-page-images{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &-images{
        > div {
            *{
                margin-bottom: 15px;

            }
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .property-single-page{
        &-header{
            height: 48vh;
            &-title{
                bottom: #{$spacing-desk}px;
                left: #{$spacing-desk}px;
            }
        }
    
        &-headerlinks{
            &-wrapper{
                display: flex;
                justify-content: space-between;
            }
            &-left{
                flex-direction: row;
                a{
                    padding-left:15px;
                    padding-right:15px;
                }
                .tag{
                    max-width: 100%;
                }
            }
            &-right{
                display: flex;
                font-size: 20px;
                .share{
                    display: block;
                    padding-right: 15px;
                    padding-left: 15px;
                }
                a{
                    padding-left:10px ;
                }
            }
        }
    
        &-content{
            &-wrapper{
                display: flex;
                justify-content: space-between;
                flex-direction: row;
            }
            &-left{
                order:1;
                width: calc(55% - 25px);
            }
            &-property{
                h2{
                    margin-top: 0;
                }
                order:2;
                margin-bottom:40px;
            }
            &-right{
                width: 35%;
            }
    
            &-propertymeta{
                margin-bottom:40px;
                >*{
                    margin-bottom: 5px;
                }
            }
            &-components{
                >*{
                    margin-top: 5px;
                }
            }
        }
    
        &-images{
            > div {
                *{
                    margin-bottom: unset;
                }
                display: flex;
                justify-content: space-between;
                margin-bottom: 25px;
    
                .img-1{width: 30%;}
                .img-2{width: calc(70% - 25px);}
    
                .img-3, .img-4{width: calc(50% - (25px / 2))}
    
                .img-5{width: 100%;}
            }
        }
    }

}