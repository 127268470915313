
// hide desktop header
.dmenu-container{
    display: none;
}

// mobile header
.mob-menu-container{
    display: flex;
    align-items: center;
    width: 100%;

    &.mob-menu-home{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background-color: transparent;
        transition: background-color 0.3s;
    }
    .mobile-logo {
        text-align: center;
        width: 100%;
        img{
            width: 130px;
            padding-top: 10px;
        } 
    }
    .mobile-menu{
        .bm-overlay{
            z-index: -100 !important;
        }
        /* Position and sizing of burger button */
        .bm-burger-button {
            position: fixed;
            width: 30px;
            height: 30px;
            right: 20px;
            top: 20px;
            button {
                outline: unset !important;
            }
            
        }

        /* Color/shape of burger icon bars */
        .bm-burger-bars {
            background: $black;
            background: #000;
            height: 3px !important;
        }

        /* Color/shape of burger icon bars on hover*/
        .bm-burger-bars-hover {
            background: $purple;
        }

        /* Position and sizing of clickable cross button */
        .bm-cross-button {
            right: 30px !important;
            top: 30px !important;
        }

        /* Color/shape of close button cross */
        .bm-cross {
            background: $black;
            width: 2px !important;
            height: 20px !important;
        }

        /*
        Sidebar wrapper styles
        Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
        */
        .bm-menu-wrap {
            position: fixed;
            height: 100%;
            top: 0;
        }

        /* General sidebar styles */
        .bm-menu {
            background: $white;
            padding: 2.5em 1.5em 0;
        }

        /* Morph shape necessary with bubble or elastic */
        .bm-morph-shape {
            fill: #373a47;
        }

        /* Wrapper for item list */
        .bm-item-list {
            color: #b8b7ad;
            padding: 0.8em;
        }

        /* Individual item */
        .bm-item {
            display: inline-block;
        }

        /* Styling of overlay */
        .bm-overlay {
            background: rgba(0, 0, 0, 0.3);
        }
    } 

    .bm-menu{
        .bm-item-list {
            padding-top: 0px !important;
            *{
                text-transform: uppercase;
                text-align: center;
                font-size: 17px;
                color: $black;
                outline: unset;
            }

            .mob-menu-item-container{
                display: none;
            }

            > *:not(.arabic-menu){
                &:hover, &:active{
                    font-weight: bold;
                    .mob-menu-item-container{
                        display: flex;
                        flex-direction: column;
                        margin-top: 8px;
                        > *{
                            margin-bottom: 5px;
                        }
                    }
                }
                margin-bottom: 15px;
            }

            .arabic-menu{
                position: absolute;
                top: 30px;
                left: 30px;
                font-size: 25px;
            }

            .logo-item{
                margin-bottom: 30px;
                img{
                    width: 130px;
                }
            }

            .mobile-socialmmedia {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 30px;

                > * {
                    margin-left: 10px;margin-right: 10px;
                }
            }
            
            .mobile-search {
                margin-top: 80px;
            }
        }
    }
}




@media only screen and (min-width: $breakpoint) {
    // hide mobile menu on desktop
    .mob-menu-container{
        display: none;
    }

    header {
        border-bottom: 1px solid $black;

        // sticky menu
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 2000;
        & + div[class*="page"]{
            padding-top: 73px; // the height of header
        }
    }

    // desktop header
    .dmenu-container{
        display: unset;
        &.dmenu-home{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background-color: transparent;
            transition: background-color 0.3s;
        }
        
        &:after {
            position: absolute;
            content: "";
            background: $black;
            width: 80%;
            height: 1px;
            left: 10%;
        }
        .dmenu-wrapper{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 80%;
            margin: 0 auto;
            
            .dmenu {
                &-ul{
                    margin-top:0px;
                    margin-bottom: 0px;
                    display: flex;
                    align-items: center;
                    list-style: none;
                    padding-left: 0;
    
                    li{
                        height: 70px;
                        > a{
                            display: block;
                            padding-top: 25px;
                            padding-bottom: 25px;
                            color: $black;
                            font-weight: 400;
                        }
                        .arabic-btn{
                            font-family: $familyA;  
                            padding-top: 25px;
                        }
                        .english-btn{
                            padding-bottom: 16px;
                            font-family: $family;  
                        }
                        .contact-btn{
                            height: 85px;
                            padding-top: 25px;
                        }
                        
                        border-bottom: 3px solid transparent;
                        transition: border-color 0.4s;
                        &:hover, &.active{
                            border-color: $purple;
                        }
    
                        &:not(:last-child){
                            margin-right:40px;
                        }
                        $menu-height: 73px;
                        .content-container {
                            display: none;
                            position: absolute;
                            width: 100%;
                            border-top: 1px solid $black;
                            background: $white;
                            top: $menu-height;
                            height: calc(100vh - #{$menu-height});
                            left: 0;
                            .content-wrapper{
                                width: 80%;
                                margin: 0 auto;
                                padding-top: 30px;
                                img{
                                    padding-bottom: 10px;
                                }
                                .header-nav-content-subtitle{
                                    margin-bottom: 20px;
                                    font-size: 20px;
                                    display: block;
                                } 
                                .header-nav-section{
                                    display: flex;
                                    justify-content: space-between;
                                   
                                    .header-nav-content-left {
                                        display: flex;
                                        flex-direction: column;
                                        width: 40%;
                                    }
                                    
                                    .header-nav-content-right {
                                        display: flex;
                                        flex-direction: column;
                                        width: 58%;
                                    }
    
                                    [class^="header-nav-content-"]{                                    
                                        a{
                                            margin-bottom: 10px;
                                            color:$black;
                                            font-size: 15px;
    
                                            &.all-projects{
                                                margin-top:15px;
                                            }
                                        }
                                    }
                                }
                                &.content-properties{
                                    .header-bottom-section {
                                        display: flex;
                                        margin-top: 40px;
                                        justify-content: space-between; 
                                        align-items: flex-end; 
    
                                        .header-bottom-left {
                                            width: 40%;
                                            &-content{
                                                display: flex;
                                                justify-content: space-between;
                                                span{font-size: 15px;}
                                                a{font-size: 13px;}
                                            }
                                        }
                                        .header-bottom-right {
                                            width: 50%;
                                            display: flex;
                                            flex-direction: column;
                                            border-bottom: 2px solid $purple;
                                            padding-bottom: 20px;
                                            margin-bottom: 20px;
                                            .life{
                                                font-size: 25px;
                                                font-weight: $bold;
                                                color: $purple;
                                            }
                                            .hashtag{
                                                display: flex;
                                                justify-content: space-between;
                                                span{ 
                                                    font-size: 15px;
                                                    font-weight: $bold;
                                                    color: $purple;
                                                }
                                                a{font-size: 15px;}
                                            }
                                        }
                                    }
                                }
                                &.content-about{
                                    .header-nav-bottom{
                                        margin-top: 80px;
                                        .header-explore-section{
                                            border-top: 1px solid $black;
                                            div{
                                                padding-top: 15px;
                                                padding-bottom: 15px;
                                                border-bottom: 1px solid $black;
                                                display: flex;
                                                justify-content: space-between;
                                                *{font-size: 18px;}
                                                a{color: $black; }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
            }
    
            .image-logo{
                .lg-logo{
                    padding-top: 5px;
                    padding-bottom: 5px;
                    width: 150px;
                }            
                .sm-logo{
                    padding-bottom: 16px;
                    display: none;
                }
            }
        }
    
        &.open {
            background-color: $white;
            //to obtain the border on homepage!
            border-bottom: 1px solid $black;

            &:after{
                content: unset;
            }
            .dmenu-wrapper{
                .dmenu {
                    &-ul{
                        li{
                            .content-container {
                                &.open {
                                    display: unset;
                                    z-index:1;
                                    overflow: scroll;
                                }
                            }
                        }
                    }
        
                }
    
                .image-logo{
                    .lg-logo{
                        display: none;
                    }            
                    .sm-logo{
                        transition: width 0.3s;
                        display: unset;
                    }
                }
            }
        }
    }
}