.home-header {
  background-image: url(../img/home-bannerr.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: flex-end;

  &-text {
    background-color: $white;
    width: calc(100% - #{$spacing-mob + $spacing-mob}px);
    margin: #{$spacing-mob + $spacing-mob}px auto;
    padding: 17px;
    box-sizing: border-box;

    h5 {
      margin: 0 0 10px;
      color: $purple;
      font-size: 26px;
      font-weight: $medium;
    }

    strong {
      font-size: 20px;
    }
  }
}

.desc-card {
  &-title {
    margin-bottom: 0px;
    margin-top: 10px;
    line-height: 1.1;
  }
}

.category-section {
  .cat-card {
    &-title {
      margin: 10px 0;
    }
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}

.alargan-life {
  &-wrapper {
    padding-top: #{$spacing-mob}px;
    padding-bottom: #{$spacing-mob}px;
    border-top: 10px solid $purple;
    border-bottom: 10px solid $purple;
  }

  &-content {
    display: flex;
    flex-direction: column-reverse;
    > * {
      margin-bottom: 20px;
    }
    .hashtag {
      > span {
        font-size: 20px;
        font-weight: $bold;
      }
    }
    .life {
      font-size: 30px;
      font-weight: $regular;
    }
  }

  &-images {
    img:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.news-section {
  > h1 {
    border-bottom: 1px solid $black;
    padding-bottom: 15px;
  }

  .news-card {
    &-title {
      h2 {
        margin-bottom: 5px;
      }
    }

    &-text {
      > * {
        display: inline-block;
      }
    }

    &-meta {
      font-size: 17px;
      font-weight: $medium;
      margin-top: 0px;
    }

    &-link {
      margin-bottom: 10px;
    }
  }
}

.contact-wrapper {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  padding-bottom: 15px;

  form {
    > * {
      width: 100%;
      margin-bottom: 10px;
    }
    .select-field {
      margin-top: 15px;
    }
    .action-btns {
      display: flex;
      justify-content: flex-end;
      .submit-btn {
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint) {
  .home-header {
    background-size: 100%;
    &-text {
      width: 500px;
      margin: 0 0 #{$spacing-desk}px #{$spacing-desk}px;
      padding: 20px;
      h5 {
        margin-bottom: 15px;
        color: $purple;
        font-size: 40px;
      }

      strong {
        font-size: 30px;
      }
    }
  }

  .desc-card {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &-title {
      margin-bottom: 30px;
    }
    &-image {
      width: 66%;
    }
    &-text {
      width: 32%;
    }
  }

  .category-section {
    display: flex;
    justify-content: space-between;
    .cat-card {
      width: 32%;
    }
  }

  .alargan-life {
    $spacing: 25px;
    &-wrapper {
      padding-top: $spacing;
      padding-bottom: $spacing;
    }

    &-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      > * {
        margin-bottom: $spacing;
      }
    }

    &-images {
      display: flex;
      justify-content: space-between;
      img {
        width: 66%;
      }
      img:not(:last-child) {
        width: 32%;
        margin-bottom: unset;
      }
    }
  }

  .news-section {
    h1 {
      margin-bottom: 60px;
    }
    .news-card {
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      > * {
        width: 28%;
      }

      &-text {
        > * {
          display: inline;
        }
      }

      &-title {
        h2 {
          margin-bottom: unset;
        }
      }

      &-meta {
        font-size: 20px;
        margin-bottom: 0px;
      }

      &-info {
        margin-left: 5px;
        font-size: 20px;
      }

      &-link {
        margin-bottom: unset;
        display: block;
        margin-top: 15px;
      }
    }
  }

  .contact-wrapper {
    display: flex;
    padding-top: 20px;
    > h1 {
      width: 45%;
      padding-right: 15px;
    }
    form {
      width: 60%;
    }
  }
}
