.page-section{
    
    .right-section{
        .available-contact{
            &-wrapper{
                &:not(:last-child){
                    border-bottom: 1px solid $black;
                }
            }

            &-left{
                margin-bottom: 20px;
            }
            
            &-right{
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (min-width: $breakpoint) {
    .page-section{
        .available-contact{
            &-container{
                display: flex;
                justify-content: space-between;
            }
            &-left{
                width: 60%;
                margin-bottom: 20px;
            }
            
            &-right{
                width: 30%;
                margin-bottom: 20px;
            }
        }
    }
}